import {ContactPluginComponent} from "../../../../../../plugins/wwp-contact/public/js/contact-es6";

export class ContactThemePluginComponent extends ContactPluginComponent {
  init() {
    let $forms = this.$context.find('form.contactForm');
    $forms.each((i, form) => {
      this.registerFormSubmit($(form));
      this.registerFormFieldReveals($(form));
    });
    if ($forms.length > 1) {
      this.registerFormSwitcher($forms);
    }
  }

  registerFormFieldReveals($form) {
    //Specify conditional fields for form 8
    //First, let's specify the rules for each field value and the fields to reveal
    /*
    * The rules are as follow:
    * - If the checkbox with id "checkboxes-8" is checked and its value is "cb4", reveal the field with class "message-wrap"
    * - If the radio with id "radio-8" is checked and its value is "rd3", reveal the field with class "adresse-wrap"
     */
    let conditionalFields = {
      'satisfaction-checkbox-2-8': {
        'values': ['5', '11', '17', '47', '23', '29', '35', '41'],
        'fields': ['.satisfaction-autres-wrap']
      },
      'satisfaction-checkbox-2-9': {
        'value': '11',
        'fields': ['.satisfaction-autres-wrap']
      },
      'satisfaction-checkbox-2-10': {
        'value': '17',
        'fields': ['.satisfaction-autres-wrap']
      },
      'satisfaction-checkbox-2-11': {
        'value': '47',
        'fields': ['.satisfaction-autres-wrap']
      },
      'satisfaction-checkbox-2-12': {
        'value': '23',
        'fields': ['.satisfaction-autres-wrap']
      },
      'satisfaction-checkbox-2-13': {
        'value': '29',
        'fields': ['.satisfaction-autres-wrap']
      },
      'satisfaction-checkbox-2-14': {
        'value': '35',
        'fields': ['.satisfaction-autres-wrap']
      },
      'satisfaction-checkbox-2-15': {
        'value': '41',
        'fields': ['.satisfaction-autres-wrap']
      },
      /*'checkboxes-8': {
        'values': ['cb1', 'cb3'],
        'fields': ['.message-wrap']
      },
      'satisfaction-8': {
        'values': ['5', '8'],
        'fields': ['.adresse-wrap']
      }*/
    }
    //Based on those rules, hide the fields that should be hidden
    for (let field in conditionalFields) {
      let fieldData = conditionalFields[field];
      for (let i = 0; i < fieldData.fields.length; i++) {
        let $fieldToHide = $form.find(`${fieldData.fields[i]}`);
        $fieldToHide.hide();
      }
    }
    //Then, register the change event on each field to reveal the fields that should be revealed
    for (let field in conditionalFields) {
      let fieldData = conditionalFields[field];
      let $cbFields = $form.find(`#${field} input[type="checkbox"]`);
      let $radioFields = $form.find(`#${field} input[type="radio"]`);

      $cbFields.on('change', (e) => {
        let intersection = [];
        if (fieldData.values) {
          //check if fieldData.values intersects with the values of the checked checkboxes
          let checkedValues = [];
          $cbFields.each((i, cb) => {
            if ($(cb).is(':checked')) {
              checkedValues.push($(cb).val());
            }
          });
          intersection = checkedValues.filter(x => fieldData.values.includes(x));
        }

        //If the right checkbox is checked, reveal the fields, else, hide them
        if ((fieldData.value && $(e.currentTarget).val() === fieldData.value) || (intersection.length > 0)) {
          for (let i = 0; i < fieldData.fields.length; i++) {
            let $fieldToShow = $form.find(`${fieldData.fields[i]}`);
            $fieldToShow.show();
          }
        } else {
          for (let i = 0; i < fieldData.fields.length; i++) {
            let $fieldToHide = $form.find(`${fieldData.fields[i]}`);
            $fieldToHide.hide();
          }
        }

      });
      $radioFields.on('change', (e) => {
        //If the right checkbox is checked, reveal the fields, else, hide them
        if ((fieldData.value && $(e.currentTarget).val() === fieldData.value) || (fieldData.values && fieldData.values.includes($(e.currentTarget).val()))) {
          for (let i = 0; i < fieldData.fields.length; i++) {
            let $fieldToShow = $form.find(`${fieldData.fields[i]}`);
            $fieldToShow.show();
          }
        } else {
          for (let i = 0; i < fieldData.fields.length; i++) {
            let $fieldToHide = $form.find(`${fieldData.fields[i]}`);
            $fieldToHide.hide();
          }
        }
      });
    }
  }
}

window.pew.addRegistryEntry({
  key: 'wdf-plugin-contact',
  classDef: ContactThemePluginComponent,
  domSelector: '.module-contact'
});
