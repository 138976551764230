(function ($, ns) {

    "use strict";

  /**
   * init module scripts, relative to its context (multiple context of the same module may exist in a page)
   * @param jQuery $context wraper div of the module
   */
    var ThemeNewsletter = function (context) {
        this.$context = (context instanceof jQuery) ? context : $(context);
        this.init();
    };

    var parentPrototype = window.pew.getRegistryEntry('wdf-plugin-newsletter').classDef.prototype;
    ThemeNewsletter.prototype = jQuery.extend({}, parentPrototype);

    ThemeNewsletter.prototype.init = function () {
        parentPrototype.init.call(this);
        this.registerClose();
        this.registerShow();
        this.registerPostcodeAutocomplete();
    };
    ThemeNewsletter.prototype.registerShow = function () {
        var self = this;
        const $popinWrap = this.$context.parents('.newsletter-popin');
        if (!$popinWrap.length) {
            return;
        }

        var cookieSet = this.checkIfCookieSet("newsletter_closed_popup");
      //console.log('Popin cookie set', cookieSet);

      //If cookie set, do not show
        if (cookieSet) {
            return;
        }

      //Then check if trackers set
        const trackersSet = this.checkIfCookieSet("enabled_trackers");
      //console.log('Trackers cookie set', trackersSet);
        if (!trackersSet) {
          //Wait for trackers
            this.waitForTrackers($popinWrap);
        } else {
          //Show now
            this.showPopin($popinWrap);
        }

    };
    ThemeNewsletter.prototype.showPopin = function ($popinWrap) {
      //show popin
        $popinWrap.removeClass('closed');
        $('body').addClass('has-newsletter-popin');

      //show overlay
        var $overlay = $popinWrap.next('.popin-overlay');
        if ($overlay.length) {
            $overlay.removeClass('closed');
        }

      // Uncomment the content of nl-subsc-image
        var $imageDiv = $popinWrap.find('.nl-subsc-image');
        var commentedHtml = $imageDiv.html();
        var uncommentedHtml = commentedHtml.replace(/<!--/g, '').replace(/-->/g, '');
        $imageDiv.html(uncommentedHtml);

      //Record view event
        setTimeout(() => {
            var EventManager = window.EventManager || $(document);
            const popinRef = $popinWrap.data('ref');
            EventManager.trigger('Tracking.customEvent', {
                action: 'popin_view',
                category: 'newsletter',
                label: 'Popin : ' + popinRef
            });
        }, 500);
    };
    ThemeNewsletter.prototype.waitForTrackers = function ($popinWrap) {
      //console.log('wait for trackers', $popinWrap);
        var EventManager = window.EventManager || $(document);
        EventManager.on('trackers.submit.success', () => {
            this.showPopin($popinWrap);
        });
    };
    ThemeNewsletter.prototype.registerClose = function () {
        var self = this;
        const $popinWrap = this.$context.parents('.newsletter-popin');
        if (!$popinWrap.length) {
            return;
        }
        const $closeBtn = $popinWrap.find('.nl-subsc-close');
        if (!$closeBtn.length) {
            return;
        }

        $closeBtn.on('click', (e) => {
            self.closePopin($popinWrap, 30);

          //register close event
            var EventManager = window.EventManager || $(document);
            var listInputs = $popinWrap.find('form input[name="list[]"]').val();
            const eventData = {
                action: 'form_closed',
                category: 'newsletter',
                label: 'Fermeture page : ' + window.location.pathname,
                value: listInputs
            };
            EventManager.trigger('Tracking.customEvent', eventData);
        });

    }

    ThemeNewsletter.prototype.closePopin = function ($popinWrap, exDays) {
      //remove popin
        $popinWrap.addClass('closed');
        $('body').removeClass('has-newsletter-popin');

      //remove overlay
        const $overlay = $popinWrap.next('.popin-overlay');
        if ($overlay.length) {
            $overlay.addClass('closed');
        }

      //Persist close
        this.setCookie("newsletter_closed_popup", 'true', exDays);
    }

    ThemeNewsletter.prototype.setCookie = function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    };

    ThemeNewsletter.prototype.checkIfCookieSet = function (cname) {
        const cookieVal = this.getCookieVal(cname);

        return (cookieVal && cookieVal.length > 0);
    };

    ThemeNewsletter.prototype.getCookieVal = function (cname) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    ThemeNewsletter.prototype.onFormSuccess = function (res, $form) {
        parentPrototype.onFormSuccess.call(this, res, $form);
        const $popinWrap = $form.parents('.newsletter-popin');
        if ($popinWrap.length) {
          //send a custom event for this popup
            const popinRef = $popinWrap.data('ref');
            const eventData = {
                action: 'popin_success',
                category: 'newsletter',
                label: 'Popin : ' + popinRef
            };
            var EventManager = window.EventManager || $(document);
            EventManager.trigger('Tracking.customEvent', eventData);

            setTimeout(() => {
                this.closePopin($popinWrap, 365);
            }, 2000);
        }
    }

    ThemeNewsletter.prototype.registerPostcodeAutocomplete = function () {
      //Check if a postcode input is present
        var $postcodeLookupInput = this.$context.find('input[name="postcode_lookup"]');
        var $postcodeInput = this.$context.find('input[name="postcode"]');
        var $cityInput = this.$context.find('input[name="city"]');
        var $adminArea1Input = this.$context.find('input[name="admin_area_level_1"]');
        var $countryInput = this.$context.find('input[name="country"]');

        if (!$postcodeLookupInput.length) {
            return;
        }

        var self = this;

      //Wait for the script to be loaded
      // Attach your callback function to the `window` object
        window.initPostcodeAutocomplete = function () {
          // JS API is loaded and available
            var input = $postcodeLookupInput[0];

            const validityMessage = window.wonderwp
            && window.wonderwp.i18n
            && window.wonderwp.i18n['wwp-newsletter']
            && window.wonderwp.i18n['wwp-newsletter']['nl.postcode.validation']
            ? window.wonderwp.i18n['wwp-newsletter']['nl.postcode.validation']
            : "Please choose a valid address from the suggestions.";
            input.setCustomValidity(validityMessage);
            $postcodeLookupInput.on('input', function () {
                input.setCustomValidity('');
                if (input.value === '') {
                    input.setCustomValidity(validityMessage);
                    $(input).removeClass('valid');
                }
            })
            .on('blur', function () {
                if ($cityInput.val() === '') {
                    input.setCustomValidity(validityMessage);
                    $(input).removeClass('valid');
                }
            });

            var locale = window.wonderwp.locale || null,
            langCode = locale ? locale.split('_')[0] : null,
            countryCode = locale ? locale.split('_')[1] : null;

            var autocompleteOptions = {
                types: ['postal_code'],
                fields: ['name', 'address_components', 'geometry'] // Specify the fields to retrieve
            };

            if (langCode) {
                autocompleteOptions.language = langCode;
            }
            if (countryCode) {
                if (countryCode==='GB') {
                    autocompleteOptions.componentRestrictions = { country : ['GB','UK', 'IE'] };
                } else {
                    autocompleteOptions.componentRestrictions = {country: countryCode.toLowerCase()};
                }
            }

          //console.log(autocompleteOptions);

          //Init the autocomplete
            var autocomplete = new google.maps.places.Autocomplete(input, autocompleteOptions);

            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();

                if (!place.geometry) {
                    alert("Place details not available for input: '" + place.name + "'");
                    return;
                }

                var postalCode = self.getPostalCode(place); // Extract postal code from place
                var city = self.getCity(place); // Extract city from place
                var adminArea1 = self.getAdminArea1(place); // Extract admin area 1 from place
                var country = self.getCountryCode(place); // Extract country from place

              //console.log("City: " + city + ", Postal Code: " + postalCode + ", Admin Area 1: " + adminArea1 + ", Country: " + country);

              //populate the fields
                $postcodeInput.val(postalCode);
                $cityInput.val(city);
                $adminArea1Input.val(adminArea1);
                $countryInput.val(country);
                $postcodeLookupInput[0].setCustomValidity('');
                $postcodeLookupInput.addClass('valid');
            });
        };

    }

    ThemeNewsletter.prototype.getPostalCode = function (place) {
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (addressType === 'postal_code') {
                return place.address_components[i].long_name;
            }
        }
        return 'N/A'; // If postal code is not available
    };

    ThemeNewsletter.prototype.getCity = function (place) {
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (addressType === 'locality') {
                return place.address_components[i].long_name;
            }
            if (addressType === 'postal_town') {
                return place.address_components[i].long_name;
            }
        }
        return 'N/A'; // If city is not available
    };

    ThemeNewsletter.prototype.getAdminArea1 = function (place) {
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (addressType === 'administrative_area_level_1') {
                return place.address_components[i].long_name;
            }
        }
        return 'N/A'; // If admin area 1 is not available
    };

    ThemeNewsletter.prototype.getCountryCode = function (place) {
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (addressType === 'country') {
                return place.address_components[i].short_name;
            }
        }
        return 'N/A'; // If country is not available
    }


    window.pew.addRegistryEntry({
        key: 'wdf-plugin-newsletter',
        domSelector: '.module-newsletter',
        classDef: ThemeNewsletter
    });


})(jQuery, window.wonderwp);
