import {SliderCarouselComponent} from "../../../../includes/Components/Slider/SliderCarousel/SliderCarouselComponent";

(function ($, ns) {
  "use strict";

  var moduleActuTheme = function ($context, opts) {
    this.dom = $context;
    this.$context = $($context);

    var defaultOpts = {
      pushState: (ns.actu && ns.actu.opts && ns.actu.opts.pushState !== undefined) ? ns.actu.opts.pushState : true,
      autoSubmitFilters: (ns.actu && ns.actu.opts && ns.actu.opts.autoSubmitFilters !== undefined) ? ns.actu.opts.autoSubmitFilters : true,
    };
    opts = opts || {};
    this.opts = $.extend(defaultOpts, opts);

    this.init();
  };

  var parentPrototype = window.pew.getRegistryEntry("wdf-plugin-actu").classDef.prototype;
  moduleActuTheme.prototype = jQuery.extend({}, parentPrototype);

  moduleActuTheme.prototype.registerEvents = function () {
    parentPrototype.registerEvents.call(this);
    if (this.$context.find('.actus-carousel').length > 0) {
      this.registerSlider();
    }
    this.registerFiltersToggle();
  };

  // see https://swiperjs.com/swiper-ap
  moduleActuTheme.prototype.registerSlider = function () {
    this.options = {
    };
    new SliderCarouselComponent(this.$context.find('.actus-carousel'), this.options);
  };

  /**
   *     this.options = {
   *       slideWidth: 413,
   *       minSlides: 1,
   *       maxSlides: 3,
   *       moveSlides: 1,
   *       slideMargin: 16,
   *       shrinkItems: true,
   *       pager: false,
   *       touchEnabled: false,
   *       infiniteLoop: false,
   *       hideControlOnEnd: true,
   *       controls: numberOfActus > treshold
   *     };
   */

  moduleActuTheme.prototype.registerFiltersToggle = function () {
    var $filtersForm = this.$context.find('form.news-filters'),
      $filtersToggle = $filtersForm.find('label[for="cat"]'),
      $filtersContainer = $filtersForm.find('#cat');

    $filtersToggle.on('click', function (e) {
      e.preventDefault();
      $filtersContainer.parent().toggleClass('opened');
    });

    $(document).on('click', function (e) {
      if (!$filtersForm.is(e.target) && $filtersForm.has(e.target).length === 0 && $filtersContainer.parent().hasClass('opened')) {
        $filtersContainer.parent().removeClass('opened');
      }
    });
  };

  window.pew.addRegistryEntry({key: "wdf-plugin-actu", domSelector: ".module-actu", classDef: moduleActuTheme});

})(jQuery, window.wonderwp);
