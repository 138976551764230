(function ($, ns) {

    "use strict";


    var memoryGame = function (context) {
        this.$context = (context instanceof jQuery) ? context : $(context);
        this.arrayOfDifficulty = [];
        this.difficulty = null;
        this.timerBegin = null;
        this.idGame = null;
        this.arrayCard = [];
        this.checkedCards = [];
        this.nbTentatives = 0;
        this.nbPairFound = 0;
        this.maxAttempts = 0;
        this.nbErrors = 0;
        this.win = false;
        this.gameTime = 0;
        this.gameTimer = null;
        this.init();
    };

    memoryGame.prototype = {

        init: function () {
            this.constDifficult();
            this.buttonDifficult();
            this.defaultDifficulty();
            this.buttonPlay();
            this.buttonDiscoverCards();

            this.maxAttempts = parseInt(this.$context.find(".triesCounter").val());
            this.gameTime = this.$context.find(".timerGame").val();
        },
        /**
         * fonction qui va récupérer les niveaux de difficultés
         */
        constDifficult : function(){
            let _this= this;
            this.$context.find('#block-btn-difficult .btn-difficulty').each(function(){
                let difficulty = $(this).data('difficulty');
                _this.arrayOfDifficulty.push(difficulty);
            });
        },
        /**
         * fonction executée au moment du click sur un des boutons de difficulté pour récupérer cette derniere
         */
        buttonDifficult : function(){
            var _this = this;
            this.$context.find("#block-btn-difficult .btn-difficulty").on("click", function(){
                _this.$context.find('#block-btn-difficult .btn-difficulty').each(function(){
                    $(this).find("span").removeClass("active-difficulty");
                });
                _this.difficulty = $(this).data("difficulty");
                $(this).find("span").addClass("active-difficulty");
            });

            this.timerBegin = this.$context.find(".timerBegin").val();
            this.idGame = this.$context.find(".idGame").val();

            //console.log('timer begin :'+this.timerBegin);
        },
        defaultDifficulty: function(){
            if(this.$context.find('#block-btn-difficult .btn-difficulty').length >= 2) {
                this.$context.find('#block-btn-difficult .btn-difficulty').eq(1).trigger("click");
            }
        },
        /**
         * quand on click sur le boutton jouer ca cache les boutons de difficulté et affiche le block de préparation pour avertir le joueur
         */
        buttonPlay : function(){

            var _this = this;
            this.$context.find("#block-btn-play .btn-play").on("click", function(){
                if(_this.difficulty != null && ( _this.arrayOfDifficulty.indexOf(_this.difficulty)>=0 ) ){
                    _this.getCardAjax();
                }
                else{
                    _this.errorMsg("Veuillez choisir une difficulté !");
                }
            });
        },
        /**
         * quand on click sur le boutton pour découvrir les cartes
         */
        buttonDiscoverCards: function(){
            var _this = this;
            this.$context.find(".btn-discover").on("click", function(){

                $(".memoryBlockReady").hide();
                $(".memoryPlayGame").fadeIn();

                //selon le nombre de cartes on affiche sur 3 ou 4 colonnes
                _this.setNbColumns();

                // début du jeu
                // si temps memorisation des cartes > 0 je les montre sinon je lance le jeu directement
                if(_this.timerBegin > 0){
                    _this.displayCards();
                }else{
                    _this.memoryBegin();
                }
            });
        },
        setNbColumns : function(){
            var nbColumns = 3;
            if(this.arrayCard.length % 4 == 0){
              nbColumns = 4;
            }

            this.$context.find(".allCardsMemoryGame").removeClass("colCards-3").removeClass("colCards-4").addClass("colCards-"+nbColumns);
        },
        displayCards : function(){

            this.$context.find(".timerBeginDecompte").html(this.timerBegin).show();
            this.timerBeginDecompte();

        },
        // va me chercher les et le bon nombre selon la difficulté cartes
        getCardAjax: function(){

            let _this=this;

            $.getJSON(
                "/get-card",
                {"idGame": this.idGame, "difficulty": this.difficulty},
                function(result){
                    if(result && result.code && result.code === 200) {
                        var listCards = result.data.cards;
                        var backCards = result.data.back;
                        listCards = listCards.concat(listCards);
                        listCards = _this.shuffle(listCards);
                        let i = 0;

                        listCards.forEach(function (image) {
                            var imgObj = new memoryImage(image, backCards, i);
                            _this.arrayCard[i] = imgObj;
                            i++;
                            imgObj.insertCard(_this.$context.find("ul.allCardsMemoryGame"));
                        });

                        _this.$context.find(".memoryBlockPresentation").fadeOut();
                        _this.$context.find(".memoryBlockReady").fadeIn();
                    }
                    else {
                        _this.errorMsg("Une erreur s'est produite");
                    }
                });
        },
        errorMsg: function(msg){
            let _this = this;

            let notificationDest = _this.$context.find(".memoryAlertDifficulty");
            var EventManager = window.EventManager || $(document);
            EventManager.trigger('notification', {
              type: 'error alert-small',
              msg: msg,
              dest: notificationDest,
              focus: true
            });

        },

        timerBeginDecompte: function(){

            var _this = this;
            let timeMemorise = this.timerBegin;

            setTimer();

            // decompte du temps pour memoriser les carte
            let timerFunction = setInterval(setTimer, 1000);

            function setTimer(){

                let displayTime = timeMemorise;
                if(timeMemorise < 10){ displayTime = "0"+displayTime; }
                _this.$context.find(".timerBeginDecompte").addClass('active').html("00:"+displayTime);

                if(timeMemorise <=0){
                    clearInterval(timerFunction);
                    _this.$context.find(".timerBeginDecompte").fadeOut();
                    _this.memoryBegin();
                }

                timeMemorise--;

            }

        },

        memoryBegin:function(){
            // partie en cours
            this.flipCards();
            this.initClickCards();
            this.timerGame();
        },

        /**
         * Affiche le verso de toutes les cartes disponibles
         */
        flipCards: function(){
            let _this = this;
            for(var i=0; i<_this.arrayCard.length; i++){
                var objImage = _this.arrayCard[i];
                objImage.showVerso();
            }

            _this.$context.find(".gameCounters").delay(500).fadeIn();
        },

        /**
         * Incrémente le nombre de tentatives effectuées
         */
        updateNbErrors: function(){
            this.nbErrors = this.nbErrors + 1;
            if(this.$context.find(".triesCount").length > 0) {
              this.$context.find(".triesCount span").text(this.nbErrors);
            }
            else{
              this.$context.find(".triesCounter span").text(this.maxAttempts - this.nbErrors);
            }
        },

        /**
         * Initialise onclick event sur chaque image
         */
        initClickCards: function(){
            let _this = this;
            _this.$context.find(".imageCard img").each(function(index, item){
                _this.bindClickImg(item);
            });
        },

        timerGame: function(){
            var _this = this;

            if(_this.gameTime > 0){
                var timeToEnd = _this.gameTime;

                _this.$context.find(".timerGameDecompte span").html(timeToEnd);

                _this.gameTimer = setInterval(function(){

                    timeToEnd = timeToEnd - 1;
                    _this.$context.find(".timerGameDecompte span").html(timeToEnd);

                    if(timeToEnd <=0){
                        clearInterval(_this.gameTimer);
                        _this.$context.find(".timerGameDecompte").fadeOut();
                        _this.win = false;
                        _this.gameFinish();
                    }
                }, 1000);
            }

        },

        /**
         * Au click sur une image, on la retourne (pour l'afficher)
         * et on check si paire trouvée
         *
         * @param item
         */
        bindClickImg: function(item){
            let _this = this;
            const $parent = $(item).parent();
            $parent.off("click").on("click", function(event, index) {
              event.preventDefault();
              var $imgElem = $parent.find('img.img-verso');
              var imgIndex = $imgElem.data("position");
              var objImg = _this.arrayCard[imgIndex];

              //une fois l'image affichée, on check si la paire est ok
              objImg.revealCard(function () {
                _this.checkPaire(objImg);
              });
            });
        },

        /**
         * Regarde si une paire a été formée
         * @param imgFlipped (last flipped card)
         */
        checkPaire: function(imgFlipped){
            let _this = this;

            //recupere le nombre de cartes tournées
            let nbCardsFlipped = _this.checkedCards.length;

            //on stocke l'image tournée dans un tableau
            _this.checkedCards[nbCardsFlipped] = imgFlipped;

            //si on a 2 images (== 1 car nbCardsFlipped calculé avant insertion de la derniere image tournée)
            if(nbCardsFlipped == 1){

                //on compare
                if(_this.checkedCards[0].getRecto() == _this.checkedCards[1].getRecto()){

                    //on marque la paire comme trouvée.
                    for(var i=0; i<=1; i++){
                        _this.checkedCards[i].markAsFound();
                    }

                    //reinitialisation du tableau contenant les images révélées a analyser
                    _this.checkedCards = [];

                    //update le nombre de paire trouvées
                    _this.nbPairFound++;
                }
                else{
                    //update le nombre d'erreurs;
                    _this.updateNbErrors();

                    //on retourne les cartes - la paire était incorrecte
                    for(var i=0; i<=1; i++){
                      _this.checkedCards[i].showVerso();
                      //on permet de recliquer sur l'image
                      _this.bindClickImg(_this.checkedCards[i].$context.find("img"));
                    }

                    //réinitialisation du tableau contenant les images révélées a analyser
                    _this.checkedCards = [];
                }

                //regarde si le jeu est terminé = si toutes les paires avaient été trouvées
                _this.checkEndOfGame();
            }
        },

        checkEndOfGame: function(){
            //regarde si nombre de paires trouvées = nb de paires dispo
            if((this.nbPairFound * 2) == this.arrayCard.length){//on a trouvé toutes les paires
              this.win = true;
              this.gameFinish();
            }
            else if(this.maxAttempts > 0 && this.nbErrors >= this.maxAttempts){
              this.win = false;
              this.gameFinish();
            }
        },

        // fonction qui dit que le jeu et terminé et affiche le shortCode présentation club
        gameFinish : function(){
            let _this = this;
            _this.$context.find(".memoryPlayGame").fadeOut();

            if(_this.gameTime > 0) {
              clearInterval(_this.gameTimer);
            }

            _this.$context.find(".endMemory").fadeIn();
            if(_this.win == true){
                _this.showWinMessage();
            }
            else{
                _this.showLoseMessage();
            }

            _this.$context.find('.block-btn-replay button').on('click', function(){
                var url = window.location.href;
                if(url.match("#")){
                  var urlPieces = url.split("#");
                  url = urlPieces[0];
                }

                url = url+"#module-memory";
                window.location.href = url;
                window.location.reload();
            });
            //_this.$context.find('.pml.presentation-club').fadeIn();
        },

        showWinMessage: function(){
            let _this = this;
            _this.$context.find(".endMemory-win").fadeIn();

            var textLevel = $("button[data-difficulty='"+_this.difficulty+"']").text().replace(/x[0-9]+\s/,"");

            _this.$context.find(".memory-win-recap-level-val").text(textLevel);
            _this.$context.find(".memory-win-recap-nberrors-val").text(_this.nbErrors);
        },

        showLoseMessage: function(){
            let _this = this;
            _this.$context.find(".endMemory-lose").fadeIn();
        },

        /**
         * Melange des cartes
         *
         * @param array
         * @returns {*}
         */
        shuffle:function(array){
            array.sort(() => Math.random() - 0.5);
            return array;
        }

    };

    var memoryImage = function(image, back, index){
        this.img = image;
        this.imgBack = back;
        this.position = index;
        this.$context = null;
    };

    memoryImage.prototype = {
        generateDomCard: function(){
          var dom = "<li class='imageCard card'><a href='#'>" +
            "<img class='img-recto' src='"+this.img.image+"' data-position='"+this.position+"'>" +
            "<img class='img-verso' src='"+this.imgBack+"' data-position='"+this.position+"'>"+
            "</a></li>";
          this.$context = $(dom);
          return this.$context;
        },
        insertCard: function(cible){
            $(cible).append(this.generateDomCard());
        },
        getVerso: function(){
            return this.imgBack;
        },
        getRecto: function(){
            return this.img;
        },
        revealCard: function(callback){
            let _this = this;
            _this.$context.find("img").off("click");

            _this.showRecto(function(){
              callback && callback(_this);
            });
        },
        showRecto: function(cb){
            var src = this.getRecto();
            this.changeSrc(src,false);
            // Attendre 1s avant de retourner carte
            // (doit correspondre avec le temps de la transtion d'animation)
            setTimeout(function(){  cb && cb(); }, 1000);
        },
        showVerso: function(cb){
            var src = this.getVerso();
            this.changeSrc(src, true);
            // Attendre 1s avant de retourner carte
            // (doit correspondre avec le temps de la transtion d'animation)
            setTimeout(function(){  cb && cb(); }, 1000);
        },
        changeSrc: function(src, verso){
          if(verso == true){
              this.$context.addClass("show-verso");
              this.$context.find("img.img-recto").removeAttr("aria-label");
          }
          else{
              this.$context.removeClass("show-verso");
              this.$context.find("img.img-recto").attr("aria-label", src.ariaDesc);
          }

        },
        markAsFound: function(){
            this.$context.addClass("paire-found");
            this.$context.find("img").off("click");
        }
    };

    window.pew.addRegistryEntry({key: 'wdf-plugin-memory', domSelector: '.module-memory', classDef: memoryGame});

})(jQuery, window.wonderwp);
