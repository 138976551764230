(function ($, ns) {

    "use strict";

    /**
     * init module scripts, relative to its context (multiple context of the same module may exist in a page)
     * @param context jQuery wraper div of the module
     */
    var SearchNClick = function (context) {
        this.$context = (context instanceof jQuery) ? context : $(context);
        this.init();
    };

    SearchNClick.prototype = {
        init: function () {
            this.gameData = this.loadData();
            this.buildZones();
            this.buildCounters();
        },
        loadData: function () {
            return $.parseJSON(this.$context.find('.snc-zone-data').html());
        },
        buildZones: function () {
            var zones = (this.gameData && this.gameData.zones) || {};
            if (zones.length) {
                var $searchWrap = this.$context.find('.snc-search');
                var zonesMarkup = '';
                for (var i in zones) {
                    var z = zones[i];
                    zonesMarkup += '<button type="button" class="zone-btn" data-zone="' + i + '" style="left: ' + z.x + '%; top: ' + z.y + '%" tabindex="-1"></button>';
                }
                $searchWrap.append(zonesMarkup);
                this.registerZoneClicks();
            }
        },
        registerZoneClicks: function () {
            var t = this,
                zones = (this.gameData && this.gameData.zones) || {},
                $zoneBtns = this.$context.find('.snc-search .zone-btn');
            $zoneBtns
                .on('click', function (e) {
                    e.preventDefault();
                    var zoneId = $(this).data('zone');
                    var z = zones[zoneId];

                    //set skip timeout
                    if (!t.skipTimeout) {
                        t.setSkipTimeout();
                    }

                    if (z.ok) {
                        var imgOk = '<div class="snc-zone-feedback snc-zone-feedback-ok" style="left: ' + z.x + '%; top: ' + z.y + '%"><img src="' + t.gameData.imgOk + '" alt="right answer" /></div>',
                            $imgOk = $(imgOk);
                        $(this).replaceWith($imgOk);
                        setTimeout(function () {
                            $imgOk.addClass('active');
                        }, 10);
                        t.updateCounters('ok', t.$context.find('.snc-counter'));
                    } else {
                        var imgKo = '<div class="snc-zone-feedback snc-zone-feedback-ok" style="left: ' + z.x + '%; top: ' + z.y + '%"><img src="' + t.gameData.imgKo + '" alt="wrong answer" /></div>',
                            $imgKo = $(imgKo);
                        $(this).replaceWith($imgKo);
                        setTimeout(function () {
                            $imgKo.addClass('active');
                        }, 10);
                        t.updateCounters('ko');
                    }
                    if (t.counters.attempts.ok === t.counters.zones.ok) {
                        t.endGame('win');
                    }
                })
        },
        buildCounters: function () {
            var zones = (this.gameData && this.gameData.zones) || {};
            this.counters = {
                zones: {
                    total: zones.length,
                    ok: 0,
                    ko: 0
                },
                attempts: {
                    total: 0,
                    ok: 0,
                    ko: 0
                }
            };
            for (var i in zones) {
                var z = zones[i];
                if (z.ok) {
                    this.counters.zones.ok++;
                } else {
                    this.counters.zones.ko++;
                }
            }
        },
        updateCounters: function (part, $counterElt) {
            this.counters.attempts.total++;
            this.counters.attempts[part]++;
            if ($counterElt) {
                $counterElt.find('.attempts').html(this.counters.attempts[part]);
            }
        },
        endGame: function (outcome) {
            var t = this;
            setTimeout(function () {
                var $outcomeWrap = t.$context.find('.endgame .endgame-' + outcome),
                    $searchWrap = t.$context.find('.snc-search');
                $searchWrap.addClass('game-is-finished');
                $outcomeWrap.appendTo($searchWrap).fadeIn();
                var formFields = '<input type="hidden" name="snc-game-id" value="' + t.gameData.sncId + '" />' +
                    '<input type="hidden" name="snc-game-outcome" value="' + outcome + '" />' +
                    '<input type="hidden" name="snc-game-counters" value=\'' + JSON.stringify(t.counters) + '\'>' +
                    '<input type="hidden" name="snc-redirect-url" value="' + window.location.href + '" />';
                var $form = $outcomeWrap.find('form');
                $form.find('.submit-wrap').before(formFields);
                t.registerFormSubmit($form);
            }, 100)
        },
        registerFormSubmit: function ($form) {
            var t = this;
            $form.off('submit');
            $form.on('submit', function (e) {
                e.preventDefault();
                var $_form = $(this);
                if ($_form.hasClass('loading')) {
                    return false;
                }
                var formData = new FormData(this);
                $_form.addClass('loading');
                $_form.find('[type="submit"]').prop("disabled", true);

                var ajaxParams = $.extend({
                    url: $_form.attr('action'),
                    data: formData,
                }, t.getAjaxParams());

                $.ajax(ajaxParams)
                    .done(function (data, textStatus, jqXHR) {
                        t.submitCallBack(data, $_form);
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        t.submitCallBack({code: 500}, $_form);
                    })
                    .always(function () {
                        $_form.removeClass('loading');
                    });
            })
        },
        getAjaxParams: function () {
            return {
                type: 'POST',
                cache: false,
                contentType: false,
                processData: false
            };
        },
        submitCallBack: function (res, $form) {
            var t = this;
            if (res && res.code && res.code === 200) {
                t.onSubmitSuccess(res, $form);
                setTimeout(function () {
                    $form.find('[type="submit"]').prop('disabled', false);
                }, 5000);
            } else {
                t.onSubmitError(res, $form);
                $form.find('[type="submit"]').prop('disabled', false);
            }
        },
        onSubmitSuccess: function (res, $form) {
            console.log(res);
            window.location.href = res.data['snc-redirect-url'];
        },
        onSubmitError: function (res, $form) {

            var notifComponent = ns.app.getComponent('notification');
            var notifType = res && res.code && res.code === 202 ? 'info' : 'error',
                notifMsg = res && res.data && res.data.msg ? res.data.msg : 'Error';
            notifComponent.show(notifType, notifMsg, $form.parent());

            var topPos = $form.parent().find('.alert').offset().top;
            if (window.smoothScrollMargin) {
                topPos -= window.smoothScrollMargin;
            }

            $('html,body').animate({
                scrollTop: topPos
            }, 750);
        },
        setSkipTimeout: function () {
            this.skipTimeout = setTimeout(function () {
                console.log('skip ?');
            }, 1000);
        }
    };

  if (ns && ns.app) {
    ns.app.registerModule('SearchNClick', SearchNClick);
  } else {
    window.pew.addRegistryEntry({key: 'wdfgames-search-and-click', domSelector: '.module-SearchNClick', classDef: SearchNClick});
  }

})(jQuery, window.wonderwp);
